import { Box, Link, Typography } from '@mui/material'

import * as contentful from 'contentful'
import { useEffect, useMemo, useState } from 'react'
// const contentful = require('contentful')

const client = contentful.createClient({
  space: 'n4jg1qqwyfc5',
  accessToken: 'mLkIwhOgoNpIZgm5S6Q4gmB_GNcH4K8LTwIFVcvPS48'
})

function useContentful(client: contentful.ContentfulClientApi) {
  const [contentTypes, setContentTypes] = useState<contentful.ContentTypeCollection | null>(null)
  const [blogPosts, setBlogPosts] = useState<contentful.EntryCollection<unknown> | null>(null)
  
  useEffect(() => {
    const getContentTypes = async () => {
      const response = await client.getContentTypes()
      setContentTypes(response)
    }
    getContentTypes()
  }, [client, setContentTypes])

  useEffect(() => {
    const getBlogPosts = async () => {
      const response = await client.getEntries('Blog')
      setBlogPosts(response)
    }
    getBlogPosts()
  }, [client, setBlogPosts])

  return {
    contentTypes,
    blogPosts
  }
}

interface BlogPostProps {
  entry: contentful.Entry<unknown>;
}

const BlogPost = ({ entry }: BlogPostProps) => {
  return (
    <Box>
      This is my blog post! 
      {JSON.stringify(entry)}
    </Box>
  )
}

const Blog = () => {
  const { blogPosts } = useContentful(client)
  console.log(blogPosts)
  return (
    <Box>
      Blog
      {
        blogPosts?.items.map((entry) => {
          return (
            <BlogPost
              entry={entry}
            />
          )
        })
      }
    </Box>
  )
}

export default Blog