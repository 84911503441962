import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from 'react-router-dom'
import Blog from './Blog'
import { Box, CssBaseline } from '@mui/material'
import Nav from './Nav'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/blog',
    element: <Blog />
  }
])

root.render(
  <React.StrictMode>
    <Box>
      <CssBaseline />
      <Nav />
      <RouterProvider router={router} />
    </Box>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
