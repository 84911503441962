import { Box, Link, Typography } from '@mui/material'

const Nav = () => {
    return (
      <Box>
        <Typography component="span">
          <Link href="/">Home</Link>
        </Typography>
        <Typography component="span">
          <Link href="/blog">Blog</Link>
        </Typography>
      </Box>
    )
  }

  export default Nav